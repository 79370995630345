import {
  type DirectorySubmissionFull,
  type DirectorySubmissionFullReturn,
} from "@/components/DirectorySubmissions/Form/types";
import { type SupabaseClient } from "@supabase/supabase-js";

export async function loadSubmissionData(
  submissionId: string,
  supabase: SupabaseClient,
): Promise<DirectorySubmissionFull> {
  const { data, error } = await supabase
    .from("directory_submission")
    .select(
      `
      id,
      status,
      assignee,
      submission_status,
      firm_id,
      is_locked,
      directory_schedule_id (
        year,
        current_research_status,
        research_status_date,
        imported_at,
        researcher_notes,
        directory_canonical_practice_area_id (
          canonicalId: id,
          practice_area_name,
          location,
          guide
        )
      ),
      directory_submission_overview!directory_submission_id (
        directory_submission_overview_id: id,
        firm_name,
        department_name,
        partners_count,
        partners_male_female_ratio,
        other_lawyers_count,
        other_lawyers_male_female_ratio,
        ethnic_minority_percentage,
        lgbt_percentage,
        disability_percentage,
        department_specialties,
        coverage_feedback
      ),
      directory_submission_contact_persons!directory_submission_id (
        id,
        name,
        email,
        telephone_number
      ),
      directory_submission_department_heads!directory_submission_id (
        id,
        name,
        email,
        telephone_number
      ),
      directory_submission_partner_changes!directory_submission_id (
        id,
        name,
        joined_departed,
        joined_from_destination
      ),
      directory_submission_lawyer_info!directory_submission_id (
        id,
        name,
        comments_or_web_link,
        is_partner,
        is_ranked,
        additional_info
      ),
      directory_submission_barristers_used!directory_submission_id (
        id,
        name,
        firm_or_set,
        comments
      ),
      directory_submission_publishable_clients!directory_submission_id (
        id,
        client_name,
        is_new_client
      ),
      directory_submission_confidential_clients!directory_submission_id (
        id,
        client_name,
        is_new_client
      ),
      directory_matter!directory_submission_id (*)
    `,
    )
    .eq("id", submissionId)
    .returns<DirectorySubmissionFullReturn[]>()
    .single();

  if (error) {
    // Handle error appropriately
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("No data returned from query");
  }

  // Flatten the structure
  try {
    const flattened: DirectorySubmissionFull = {
      // Base submission fields
      id: data.id,
      status: data.status,
      assignee: data.assignee,
      submission_status: data.submission_status,
      firm_id: data.firm_id,
      is_locked: data.is_locked,

      // Schedule data
      year: data.directory_schedule_id?.year ?? null,
      imported_at: data.directory_schedule_id?.imported_at ?? null,
      researcher_notes: data.directory_schedule_id?.researcher_notes ?? null,
      research_status_date:
        data.directory_schedule_id?.research_status_date ?? null,
      current_research_status:
        data.directory_schedule_id?.current_research_status ?? null,

      // Practice area data
      guide:
        data.directory_schedule_id?.directory_canonical_practice_area_id
          ?.guide ?? null,
      location:
        data.directory_schedule_id?.directory_canonical_practice_area_id
          ?.location ?? null,
      canonicalId:
        data.directory_schedule_id?.directory_canonical_practice_area_id
          ?.canonicalId ?? null,
      practice_area_name:
        data.directory_schedule_id?.directory_canonical_practice_area_id
          ?.practice_area_name ?? null,

      // Overview data
      firm_name: data.directory_submission_overview?.firm_name ?? null,
      department_name:
        data.directory_submission_overview?.department_name ?? null,
      partners_count:
        data.directory_submission_overview?.partners_count ?? null,
      partners_male_female_ratio:
        data.directory_submission_overview?.partners_male_female_ratio ?? null,
      other_lawyers_count:
        data.directory_submission_overview?.other_lawyers_count ?? null,
      other_lawyers_male_female_ratio:
        data.directory_submission_overview?.other_lawyers_male_female_ratio ??
        null,
      ethnic_minority_percentage:
        data.directory_submission_overview?.ethnic_minority_percentage ?? null,
      lgbt_percentage:
        data.directory_submission_overview?.lgbt_percentage ?? null,
      disability_percentage:
        data.directory_submission_overview?.disability_percentage ?? null,
      department_specialties:
        data.directory_submission_overview?.department_specialties ?? null,
      coverage_feedback:
        data.directory_submission_overview?.coverage_feedback ?? null,
      directory_submission_overview_id:
        data.directory_submission_overview?.directory_submission_overview_id ??
        null,

      // Arrays with new references
      contactPersons: [
        ...(data.directory_submission_contact_persons ?? []),
      ].map((person) => ({
        id: person.id,
        name: person.name,
        email: person.email,
        telephone_number: person.telephone_number,
      })),

      departmentHeads: [
        ...(data.directory_submission_department_heads ?? []),
      ].map((head) => ({
        id: head.id,
        name: head.name,
        email: head.email,
        telephone_number: head.telephone_number,
      })),

      partnerChanges: [
        ...(data.directory_submission_partner_changes ?? []),
      ].map((change) => ({
        id: change.id,
        name: change.name,
        joined_departed: change.joined_departed,
        joined_from_destination: change.joined_from_destination,
      })),

      lawyerInfo: [...(data.directory_submission_lawyer_info ?? [])].map(
        (info) => ({
          id: info.id,
          name: info.name,
          comments_or_web_link: info.comments_or_web_link,
          is_partner: info.is_partner,
          is_ranked: info.is_ranked,
          additional_info: info.additional_info,
        }),
      ),

      barristersUsed: [
        ...(data.directory_submission_barristers_used ?? []),
      ].map((barrister) => ({
        id: barrister.id,
        name: barrister.name,
        firm_or_set: barrister.firm_or_set,
        comments: barrister.comments,
      })),

      publishableClients: [
        ...(data.directory_submission_publishable_clients ?? []),
      ].map((client) => ({
        id: client.id,
        client_name: client.client_name,
        is_new_client: client.is_new_client,
      })),

      confidentialClients: [
        ...(data.directory_submission_confidential_clients ?? []),
      ].map((client) => ({
        id: client.id,
        client_name: client.client_name,
        is_new_client: client.is_new_client,
      })),

      matters: [...(data.directory_matter ?? [])].map((matter) => ({
        id: matter.id,
        name: matter.name,
        value: matter.value,
        is_new: matter.is_new,
        firm_id: matter.firm_id,
        is_public: matter.is_public,
        created_at: matter.created_at,
        created_by: matter.created_by,
        other_info: matter.other_info,
        approved_by: matter.approved_by,
        client_name: matter.client_name,
        matter_type: matter.matter_type,
        other_firms: matter.other_firms,
        cross_border: matter.cross_border,
        included_order: matter.included_order,
        approval_status: matter.approval_status,
        potential_order: matter.potential_order,
        generation_status: matter.generation_status,
        lead_partner_name: matter.lead_partner_name,
        date_of_completion: matter.date_of_completion,
        matter_description: matter.matter_description,
        other_team_members: matter.other_team_members,
        directory_submission_id: matter.directory_submission_id,
      })),
    };

    return flattened;
  } catch (err: any) {
    console.error("Error during flattening:", err);
    throw new Error(err.message ?? JSON.stringify(err));
  }
}
