import { queryKeys } from "@/client";
import {
  type DirectorySubmissionFull,
  type QuestionComment,
  type QuestionComments,
  type SubmissionFormData,
} from "@/components/DirectorySubmissions/Form/types";
import {
  loadSubmissionData,
  saveData,
  submissionToFormData,
} from "@/components/DirectorySubmissions/Form/utils";
import Spinner from "@/components/Spinner";
import { useAuth } from "@/contexts/AuthHook";
import { type SupabaseClient } from "@supabase/supabase-js";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import isEqual from "lodash/isEqual";
import { useSnackbar } from "notistack";
import type React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";

interface SubmissionsContextValue {
  isSaving: boolean;
  setIsSaving: (value: boolean) => void;
  isLocked: boolean;
  submissionData: DirectorySubmissionFull | null;
  formData: SubmissionFormData;
  setFormData: React.Dispatch<React.SetStateAction<SubmissionFormData>>;
  initialFormData: SubmissionFormData | null;
  error: boolean;
  isFormLocked: boolean;
  showLockWarning: () => void;
  updateLockState: (is_locked: boolean) => void;
  isSidePanelOpen: boolean;
  submissionComments: QuestionComments;
  activeCommentQuestion: string | null;
  setActiveCommentQuestion: (sectionId: string | null) => void;
}

const SubmissionsContext = createContext<SubmissionsContextValue | null>(null);

export const SubmissionsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { submissionId } = useParams();
  const { supabase, firmId, userId } = useAuth();
  const [error, setError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [formData, setFormData] = useState<SubmissionFormData>({});
  const [activeCommentQuestion, setActiveCommentQuestion] = useState<
    string | null
  >(null);

  const initialFormDataRef = useRef<SubmissionFormData | null>(null);
  const previousSubmissionIdRef = useRef<string | undefined>(undefined);
  const savingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [isLocked, setIsLocked] = useState(false);

  const savingSnackbarShownRef = useRef(false);
  const lockedSnackbarShownRef = useRef(false);

  const {
    data: submissionData,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: queryKeys.directorySubmission(submissionId ?? ""),
    queryFn: async (): Promise<DirectorySubmissionFull> => {
      if (!submissionId) {
        throw new Error("No submissionId");
      }
      try {
        return await loadSubmissionData(
          submissionId,
          supabase as SupabaseClient,
        );
      } catch (err) {
        setError(true);
        throw err;
      }
    },
    enabled: !!submissionId && !!supabase,
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  const { data: submissionComments } = useQuery({
    queryKey: queryKeys.directorySubmissionComments(submissionId as string),
    queryFn: async (): Promise<QuestionComments> => {
      const { data, error } = await (supabase as SupabaseClient)
        .from("directory_submission_question_comment")
        .select(
          `
            id,
            created_at,
            text,
            question_id,
            created_by (
              id,
              first_name,
              last_name,
              email,
              avatar_url
            )
          `,
        )
        .eq("submission_id", submissionId)
        .returns<QuestionComment[]>();

      if (error) {
        throw new Error(error.message);
      }

      const keyedComments = (data ?? []).reduce<QuestionComments>(
        (acc, comment) => {
          if (!acc[comment.question_id]) {
            acc[comment.question_id] = [];
          }
          acc[comment.question_id] = [
            ...acc[comment.question_id],
            {
              id: comment.id,
              text: comment.text,
              created_at: comment.created_at,
              question_id: comment.question_id,
              created_by: {
                id: comment.created_by.id,
                first_name: comment.created_by.first_name,
                last_name: comment.created_by.last_name,
                email: comment.created_by.email,
                avatar_url: comment.created_by.avatar_url,
              },
            },
          ];
          return acc;
        },
        {},
      );

      return keyedComments;
    },
    enabled: !!submissionId && !!supabase,
    refetchOnWindowFocus: false,
    staleTime: 0,
    initialData: {},
  });

  useEffect(() => {
    setIsSaving(isRefetching);
  }, [isRefetching]);

  useEffect(() => {
    if (submissionData) {
      const newInitialFormData = submissionToFormData(submissionData);
      setFormData(newInitialFormData);
      initialFormDataRef.current = newInitialFormData;
      setIsLocked(submissionData.is_locked);
    }
  }, [submissionData]);

  useEffect(() => {
    if (submissionId === undefined) {
      setFormData({});
      initialFormDataRef.current = null;
      setIsLocked(false);
    }
  }, [submissionId]);

  useEffect(() => {
    const saveAllChanges = async () => {
      if (
        previousSubmissionIdRef.current &&
        previousSubmissionIdRef.current !== submissionId &&
        initialFormDataRef.current
      ) {
        const changedFields = Object.keys(formData).filter(
          (key) => !isEqual(formData[key], initialFormDataRef.current![key]),
        );

        if (changedFields.length > 0) {
          setIsSaving(true);
          try {
            await saveData({
              supabase,
              firmId,
              userId,
              submissionId: parseInt(previousSubmissionIdRef.current),
              enqueueSnackbar,
              formData,
              initialFormData: initialFormDataRef.current,
              changedFields,
            });
            enqueueSnackbar("Changes saved successfully", {
              variant: "success",
            });
          } catch (saveError) {
            enqueueSnackbar("Failed to save changes", { variant: "error" });
          } finally {
            setIsSaving(false);
          }

          void queryClient.invalidateQueries({
            queryKey: queryKeys.directorySubmission(
              previousSubmissionIdRef.current ?? "",
            ),
          });
        }
      }
      previousSubmissionIdRef.current = submissionId;
    };

    void saveAllChanges();
  }, [
    submissionId,
    supabase,
    firmId,
    userId,
    enqueueSnackbar,
    formData,
    queryClient,
  ]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      const hasUnsavedChanges =
        initialFormDataRef.current &&
        Object.keys(formData).some(
          (key) => !isEqual(formData[key], initialFormDataRef.current![key]),
        );

      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formData]);

  useEffect(() => {
    if (isSaving) {
      if (savingTimeoutRef.current) {
        clearTimeout(savingTimeoutRef.current);
      }

      savingTimeoutRef.current = setTimeout(() => {
        if (isSaving) {
          enqueueSnackbar(
            "Saving is taking longer than expected. Please try again.",
            { variant: "error" },
          );
          setIsSaving(false);
        }
      }, 10000);
    } else {
      if (savingTimeoutRef.current) {
        clearTimeout(savingTimeoutRef.current);
        savingTimeoutRef.current = null;
      }
    }

    return () => {
      if (savingTimeoutRef.current) {
        clearTimeout(savingTimeoutRef.current);
      }
    };
  }, [isSaving, enqueueSnackbar]);

  const showLockWarning = useCallback(() => {
    if (isSaving) {
      if (!savingSnackbarShownRef.current) {
        enqueueSnackbar("Just a moment, saving in progress", {
          variant: "warning",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
        savingSnackbarShownRef.current = true;
      }
    } else if (isLocked) {
      if (!lockedSnackbarShownRef.current) {
        enqueueSnackbar(
          "This submission has been locked. Please unlock to enable editing.",
          {
            variant: "warning",
            anchorOrigin: { horizontal: "right", vertical: "bottom" },
          },
        );
        lockedSnackbarShownRef.current = true;
      }
    }
  }, [isSaving, isLocked, enqueueSnackbar]);

  useEffect(() => {
    if (!isSaving) {
      savingSnackbarShownRef.current = false;
    }
    if (!isLocked) {
      lockedSnackbarShownRef.current = false;
    }
  }, [isSaving, isLocked]);

  const isFormLocked = isSaving || isLocked;

  const updateLockStateMutation = useMutation({
    mutationFn: async (isLocked: boolean) => {
      const { error } = await (supabase as SupabaseClient)
        .from("directory_submission")
        .update({ is_locked: isLocked })
        .eq("id", submissionId);
      if (error) throw new Error(error.message);
    },
    onSuccess: (_, isLocked) => {
      queryClient.setQueryData(
        queryKeys.directorySubmission(submissionId ?? ""),
        (oldData: any) => ({
          ...oldData,
          is_locked: isLocked,
        }),
      );
      setIsLocked(isLocked);
    },
    onError: (error) => {
      enqueueSnackbar(`Failed to update lock state: ${error.message}`, {
        variant: "error",
      });
    },
  });

  return (
    <SubmissionsContext.Provider
      value={{
        isSaving,
        setIsSaving,
        isLocked,
        submissionData: submissionData ?? null,
        formData,
        setFormData,
        initialFormData: initialFormDataRef.current,
        error,
        isFormLocked,
        showLockWarning,
        updateLockState: updateLockStateMutation.mutate,
        isSidePanelOpen: !!activeCommentQuestion, // add more items here as we get more panels
        submissionComments,
        activeCommentQuestion,
        setActiveCommentQuestion,
      }}
    >
      {submissionId && (isLoading || !submissionData) ? <Spinner /> : children}
    </SubmissionsContext.Provider>
  );
};

export const useSubmissions = () => {
  const context = useContext(SubmissionsContext);
  if (!context) {
    throw new Error("useSubmissions must be used within a SubmissionsProvider");
  }
  return context;
};
