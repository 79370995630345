import { useAuth } from "@/contexts/AuthHook";
import { type StatusBanner as StatusBannerT } from "@precedent/db-types/src/schema";
import { type RealtimeChannel } from "@supabase/supabase-js";
import { createContext, useContext, useEffect, useState } from "react";

type StatusBanner = Pick<
  StatusBannerT,
  "visible" | "firm_ids" | "text" | "style"
>;

interface StatusBannerContextType {
  banner: StatusBanner | null;
  isDismissed: boolean;
  setIsDismissed: (value: boolean) => void;
}

const StatusBannerContext = createContext<StatusBannerContextType | null>(null);

export const StatusBannerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { firmId, supabase } = useAuth();

  const [banner, setBanner] = useState<StatusBanner | null>(null);
  const [isDismissed, setIsDismissed] = useState(false);

  useEffect(() => {
    const fetchBanner = async () => {
      if (!supabase) return;

      const { data, error } = await supabase
        .from("status_banner")
        .select("*")
        .maybeSingle();

      if (error) {
        console.error("Error fetching status banner:", error);
        return;
      }

      if (data) {
        setBanner(data);
      }
    };

    void fetchBanner();
  }, [supabase]);

  useEffect(() => {
    let subscription: RealtimeChannel;

    if (supabase) {
      subscription = supabase
        .channel("status_banner_changes")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "status_banner",
          },
          (payload) => {
            setBanner(payload.new as StatusBanner);
          },
        )
        .subscribe();
    }

    return () => {
      if (subscription && supabase) {
        void supabase.removeChannel(subscription);
      }
    };
  }, [supabase]);

  const shouldShowBanner = (banner: StatusBanner | null): boolean => {
    if (!banner?.visible || isDismissed) return false;

    // Show to all firms if firmIds is null or empty
    if (!banner.firm_ids || banner.firm_ids.length === 0) return true;

    // Show only to specific firms
    return firmId ? banner.firm_ids.includes(firmId) : false;
  };

  const value = {
    banner: shouldShowBanner(banner) ? banner : null,
    isDismissed,
    setIsDismissed,
  };

  return (
    <StatusBannerContext.Provider value={value}>
      {children}
    </StatusBannerContext.Provider>
  );
};

export const useStatusBanner = () => {
  const context = useContext(StatusBannerContext);
  if (!context) {
    throw new Error(
      "useStatusBanner must be used within a StatusBannerProvider",
    );
  }
  return context;
};
