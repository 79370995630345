import SuspenseOutlet from "@/components/SuspenseOutlet";

const MinimalLayout = () => {
  return (
    <div className="min-h-screen">
      <SuspenseOutlet />
    </div>
  );
};

export default MinimalLayout;
