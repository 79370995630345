import { SubmissionsProvider } from "@/components/DirectorySubmissions/Form/SubmissionsProvider";
import Spinner from "@/components/Spinner";
import { supabaseSessionAtom } from "@/supabase";
import { useAtomValue } from "jotai";
import type React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedLayout: React.FC = () => {
  const session = useAtomValue(supabaseSessionAtom);
  const location = useLocation();

  if (session === "loading") {
    return <Spinner />;
  }

  if (!session) {
    return <Navigate to="/auth/sign-in" replace state={{ from: location }} />;
  }

  return (
    <SubmissionsProvider>
      <Outlet />
    </SubmissionsProvider>
  );
};

export default ProtectedLayout;
